import React from 'react'

import Layout from '../layouts'

import '../scss/404.scss'
import image from '../assets/images/keep-drilling.jpg'

const NotFoundPage = () => (
  <Layout>
      <div className="not-found">
        <div className="container">
          <div className="content">
            <h1>Keep drilling...</h1>
            <p class="intro">404 – The page was not found.</p>
            <p class="instructions">The page was either moved, or you have misspelled the URL. Please try again or <a href="/">go back to the homepage</a>.</p>
          </div>
        </div>
      </div>
  </Layout>
)

export default NotFoundPage
